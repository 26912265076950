<template>
    <div class="page1">
        <el-form inline size="small">
            <el-form-item label="摊位号">
                <el-select v-model="boothId" filterable placeholder="请选择" @change="changeBoothId">
                    <el-option
                            v-for="item in options1"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="费用名称">
                <el-select v-model="form.takeReturnProjectId" filterable placeholder="请选择" @change="changeFeeId">
                    <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="feeAdd">录入费用</el-button>
                <el-button type="primary" @click="dialogVisible=true">批量导入</el-button>
            </el-form-item>
        </el-form>
        <el-form inline label-width="100px" label-position="left" :model="form" v-if="boothId">
            <el-row>
                <el-col :span="6">
                    <el-form-item label="区域">

                        <div>{{ form.areaName || '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="位置">

                        <div>{{ form.placeName|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="摊位">

                        <div>{{ form.boothCode|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="身份证号码">

                        <div>{{ form.idCard|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="合同开始日期">
                        <div>{{ form.inDate|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="负责人名称">
                        <div>{{ form.signPerson|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="联系电话">
                        <div>{{ form.phone|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="摊位面积">
                        <div>{{ form.areas|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="合同类别">
                        <div>{{ form.contractTypeCn|| '-'}}</div>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="合同到期日期">
                        <div>{{ form.expireDate|| '-'}}</div>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>

        <div v-if="form.feeType==1">
            <div class="title">
                {{ form.takeReturnProjectName}}
            </div>
            <el-form inline label-width="140px" label-position="left" :rules="formRules" :model="form" ref="form">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="电价">
                            {{electricUnitPrice}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="服务费单价">
                            {{electricPrice}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="合计">
                            {{electricTotalPrice}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="购电金额" prop="totalAmount">
                            <el-input class="number" type="number" v-model.number="form.totalAmount" @input="calcFee"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="费用开始时间">
                            <el-date-picker
                                    v-model="form.feeBeginDate"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptionsStart"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="费用结束时间">
                            <el-date-picker
                                    v-model="form.feeEndDate"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptionsEnd"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="购电度数">
                            <el-input class="number" type="number" v-model="form.degrees" @input="calcAmount"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="是否需要财务确认">
                            <el-radio-group v-model="form.isConfirm">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="备注">
                            <el-input type="textarea" v-model="form.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <div v-if="form.feeType==4">
            <div class="title">
                {{form.takeReturnProjectName}}
            </div>
            <el-form inline label-width="140px" label-position="left" :rules="formRules" :model="form" ref="form">
                <el-row>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="金额" prop="totalAmount">
                            <el-input v-model="form.totalAmount"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="费用开始时间">
                            <el-date-picker
                                    v-model="form.feeBeginDate"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptionsStart">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="费用结束时间">
                            <el-date-picker
                                    v-model="form.feeEndDate"
                                    type="date"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptionsEnd">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="是否需要财务确认">
                            <el-radio-group v-model="form.isConfirm">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" :sm="10">
                        <el-form-item label="备注">
                            <el-input type="textarea" v-model="form.remark"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="mt20">
            <EButton type="primary" size="small" @click="saveData" :disabled="saveDisabled">
                确认录入
            </EButton>
            <el-button size="small">
                返回
            </el-button>
        </div>
        <!--        批量导入-->
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="dialogVisible=false"
                 width="800px"
                 @handleClick="mutiSaveData"
                 :disabled="saveDisabled">
            <el-form>
                <el-form-item label="费用名称" required>
                    <el-select v-model="multiForm.takeReturnProjectId" filterable placeholder="请选择" @change="changeFeeId">
                        <el-option
                                v-for="item in options2"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择文件">
                    <EUpload :max="1" @getList="getList" :fileTypes="['xlsx','xls']" :initList="multiForm.file?[multiForm.file]:[]">
                        <a :href="`${baseUrl}template/费用录入导入模板.xlsx`" download=""
                           class="download">下载模板</a>
                    </EUpload>

                </el-form-item>
            </el-form>
            <el-form>
                <el-form-item label="是否需要财务确认">
                    <el-radio-group v-model="multiForm.isConfirm">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import * as math from 'mathjs'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {item} from "../../assets/js/config";
  import {otherMixin} from "@/components/mixin";
  import EUpload from "@/components/EUpload";
  import {dateFormat} from '@/assets/js/common'
  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        boothId: '',
        feeId: '',
        options1: [],//摊位号下拉
        options2: [],//费用录入名称
        title: '', //费用名称
        elePriceList: [], //电费信息
        type: '',
        allAmount: '',
        form: { //费用录入传值
          areaName: '',
          placeName: '',
          boothCode: '',
          idCard: '',
          inDate: '',
          signPerson: '',
          phone: '',
          areas: '',
          contractTypeCn: '',
          expireDate: '',
          areaId: '',
          contractId: '',
          contractType: '',
          degrees: '',
          feeBeginDate: '',
          feeEndDate: '',
          feeType: '',//3:电卡充值 4:其他收费项 5:抄电表费,
          isConfirm: 2,
          orderId: '',
          placeId: '',
          remark: '',
          takeReturnProjectId: '',
          takeReturnProjectName: '',
          tenantId: '',
          tenantName: '',
          totalAmount: ''
        },
        formRules: {
          totalAmount: vxRule(true, '', "blur", "金额不能为空"),
        },
        pickerOptionsStart: {
          disabledDate: (time) => {
            let feeEndDate = this.form.feeEndDate
            if (feeEndDate)
              return time.getTime() > new Date(feeEndDate).getTime()
          }
        },
        pickerOptionsEnd: {
          disabledDate: (time) => {
            let feeBeginDate = this.form.feeBeginDate
            if (feeBeginDate)
              return time.getTime() < new Date(feeBeginDate).getTime() - 86400000
          }
        },
        //2021/3/3  新增批量导入
        dialogVisible: false,
        multiForm: {
          file: '',
          isConfirm: 2,
          takeReturnProjectId:''//收费项
        }
      }
    },
    watch: {},
    created() {
      this.cmboothinformationLabel()
      this.gettakereturnprojectLabel()
      this.getData()
    },
    components: {ETable, EDialog, EButton, EForm,EUpload},
    beforeMount() {

    },
    methods: {
      // multiExport(){
      //   if(!this.form.takeReturnProjectId){
      //     this.$message.error('请选择费用项')
      //     return false
      //   }else{
      //     this.multiForm.takeReturnProjectId=this.form.takeReturnProjectId
      //     this.dialogVisible=true
      //     // this.mutiSaveData()
      //   }
      // },
      getList(data) {
        this.multiForm.file = data[0]
      },
      async mutiSaveData() {
        if(!this.form.takeReturnProjectId){
          this.$message.info('请选择费用项')
          return false
        }
        if(!this.multiForm.file){
          this.$message.info('请上传文件')
          return false
        }
        this.setDisabled(true)
        let res = await Http.expenseEntryExport(this.multiForm, [], true)
        if (res.code === 200) {
          this.$message.success(res.msg)
          this.setDisabled(false)
          this.dialogVisible = false
          this.multiForm = {
            file: '',
            isConfirm: 2
          }
          this.$router.replace({name: 'CostList'})
        }else{
          this.$message.error(res.msg)
          this.setDisabled(false)
          this.dialogVisible = false
          this.multiForm = {
            file: '',
            isConfirm: 2
          }
        }
      },
      changeBoothId(val) {
        this.boothId = val
        this.getContractByBoothId()
      },
      changeFeeId(val) {
        this.form.takeReturnProjectId = val
        this.cancelData()
        // this.form.feeType = 4

      },
      //费用录入详情
      async getContractByBoothId() {
        if (!this.boothId) {
          this.$message.error('请选择摊位号');
          return
        }
        let res = await Http.getContractByBoothId({id: this.boothId})
        let fields = ['areaId', 'areaName', 'placeName', 'boothCode', 'idCard', 'inDate', 'signPerson', 'phone', 'areas', 'contractTypeCn', 'expireDate', 'tenantId', 'tenantName', 'placeId', 'contractId']
        if (res.code == 200) {
          // 根据摊位号获得摊位合同详情
          let data = res.data
          fields.forEach(field => {
            this.form[field] = data[field]
          })
        } else {
          fields.forEach(field => {
            this.form[field] = ''
          })
        }
      },
      //由购电金额得到购电度数
      calcFee(totalAmount) {
        let tmpDegrees = ''
        if (totalAmount) {
          tmpDegrees = math.round(math.evaluate(`${totalAmount} / ${this.electricTotalPrice}`), 2)
        }
        this.form.degrees = tmpDegrees
      },
      //由购电度数得到购电金额
      calcAmount(degrees) {
        let tmpAmount = ''
        if (degrees) {
          tmpAmount = math.round(math.evaluate(`${degrees} * ${this.electricTotalPrice}`), 2)
        }
        this.form.totalAmount = tmpAmount
      },
      cancelData() {
        //清空上一次的赋值
        this.form.totalAmount = ''
        this.form.degrees = ''
        this.form.feeBeginDate = ''
        this.form.feeEndDate = ''
        this.form.totalAmount = ''
        this.form.remark = ''
        this.form.isConfirm = 2
      },
      //选择费用项目
      feeAdd() {
        if (!this.form.takeReturnProjectId) {
          this.$message.error('请选择费用名称');
          return
        }
        //清空上一次的赋值
        this.cancelData()
        // 根据选择的费用获得label和type
        let obj = this.options2.find(item => item.value == this.form.takeReturnProjectId)
        this.form.takeReturnProjectName = obj.label

        let {timeStar:feeBeginDate,timeEnd:feeEndDate}=this.getStartAndEnd()
        this.form.feeBeginDate=feeBeginDate
        console.log(this.form.feeEndDate)
        this.form.feeEndDate=feeEndDate
        this.form.feeType = 4
      },
      getStartAndEnd(){
        let now=new Date()
        var nowMonth = now.getMonth(); //当前月
        var nowYear = now.getFullYear(); //当前年
        //本月的开始时间
        var monthStartDate = new Date(nowYear, nowMonth, 1);
        //本月的结束时间
        var monthEndDate = new Date(nowYear, nowMonth+1, 0);
        var timeStar=dateFormat.formatDate(monthStartDate, 'yyyy-MM-dd');
        var timeEnd=dateFormat.formatDate(monthEndDate, 'yyyy-MM-dd');
        return {timeStar,timeEnd}
      },
      saveData() {
        if (!this.boothId) {
          this.$message.error('请选择摊位号');
          return
        }
        if (!this.form.takeReturnProjectId) {
          this.$message.error('请选择费用名称');
          return
        }
        this.$refs['form'].validate((valid, object) => {
          console.log(object)
          if (valid) {
            this.expenseEntry()
          } else {
            return false;
          }
        })

      },
      async expenseEntry() {
        this.setDisabled(true)
        if (this.form.contractTypeCn == '长租') {
          this.form.contractType = 1
        } else if (this.form.contractTypeCn == '短租') {
          this.form.contractType = 2
        } else {
          this.form.contractType = 3
        }
        let res = await Http.expenseEntry(this.form)
        if (res.code == 200) {
          this.setDisabled(false)
          this.$message.success(res.msg);
          this.$router.replace({name: 'CostList'})
        }
      },
      //摊位信息-摊位号下拉
      async cmboothinformationLabel() {
        //已租和未租的value值0和1
        let res = await Http.cmboothinformationLabel({isRent: 1, status: 1})
        if (res.code == 200) {
          this.options1 = res.data
        }
      },
      //费用录入收费项下拉列表
      async gettakereturnprojectLabel() {
        let res = await Http.gettakereturnprojectLabel({
          status: 1
        })
        if (res.code == 200) {
          this.options2 = res.data
        }
      },
      // 获取电卡电价列表
      async getData() {
        let res = await Http.getElePriceList()
        if (res.code == 200) {
          this.elePriceList = res.data
        }
      }
    },
    computed: {
      sum() {
        return math.chain(this.electricPrice).add(this.electricUnitPrice).done()
      },
      electricTotalPrice() {
        return (this.elePriceList[0] && this.elePriceList[0].electricTotalPrice) || 0
      },
      electricPrice() {
        return (this.elePriceList[0] && this.elePriceList[0].electricPrice) || 0
      },
      electricUnitPrice() {
        return (this.elePriceList[0] && this.elePriceList[0].electricUnitPrice) || 0
      },
      baseUrl(){
        return process.env.VUE_APP_API_URL
      },
    }
  }
</script>

<style lang="scss" scoped>
    .title {
        font-weight: bold;
        padding-left: 10px;
        border-left: 2px solid #4d68ff;
        margin-bottom: 20px;
    }

    ::v-deep .number {
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        input[type="number"] {
            -moz-appearance: textfield;
        }
    }
    .download {
        color: #4D68FF;
        text-decoration: underline;
        padding: 0 10px;
    }

</style>
